<template>
  <v-row class="home pb-8">
    <v-col cols="6" class="pa-0 mt-3">
      <v-card class="panel-home" width="100%" min-height="150">
        <v-img min-width="100%" height="265" max-height="250" contain :src="require(`@/assets/backgrounds/background-panel-hello${$vuetify.theme.dark ? '--dark' : ''}.svg`)">
          <v-row class="fill-height mt-3">
            <span class="pl-8 d-block title-panel primary--text">¡Hola{{ $store.state.auth.userAccount.first_name ? ` ${$store.state.auth.userAccount.first_name}`: ''}}!
              <span class="d-inline-block text-h2">👋🏼</span>
            </span>
          </v-row>
        </v-img>
      </v-card>
    </v-col>
    <v-col cols="6" class="">
      <v-card>
        <v-card-text>
          <span class="subtitle-1 secondary--text font-weight-bold">Explorador de productos</span>
          <v-row class="mt-5" no-gutters>
            <v-col cols="3" class="text-center pa-1" v-for="p in products.filter(({show}) => show)" :key="p.id">
            <v-hover v-slot="{ hover }">
              <v-card class="elevation-0 pa-2 rounded-sm product-card " flat outlined :style="hover ? 'border: 1px solid var(--light-blue-primary)' : ''" :disabled="p.disabled" @click="setProduct(p)">
                  <v-avatar class="rounded-sm pa-5" tile color="primary" size="18">
                  <img :src="require(`@/assets/product-explorer/icons/${p.icon}--icon.svg`)" :width="10" />
                  </v-avatar>
                  <div>
                    <span class="mt-2 defaultGrey--text font-weight-medium body-2 d-inline-block text-truncate" style="max-width: 95%;">{{ p.name }}</span>
                  </div>
              </v-card>
            </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col class="d-flex align-center">
          <v-icon left color="secondary">mdi-store</v-icon>
          <span class="subtitle-1 font-weight-semibold">Tienda y POS</span>
        </v-col>
        <v-col cols="3" class="px-0">
           <v-img :height="70" contain :src="require(`@/assets/backgrounds/background-dashboard.svg`)" />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="pa-0">
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title class="px-0">
              <v-toolbar flat height="30" class="transparent">
                <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Riesgo operacional</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-row align="center" justify="center" class="mr-5">
                    <v-chip class="" color="redLight" label>
                      <v-icon size="14">mdi-alert</v-icon>
                      <span class="ml-2 font-weight-semibold">Existe riesgo</span>
                    </v-chip>
                  </v-row>
                </v-toolbar-items>
              </v-toolbar>
            </v-card-title>
            <v-card-text>
              <span class="d-block secondary--text text-h4">1.410
                <span class="body-2 secondary--text">incidencias hoy</span>
              </span>
              <v-row no-gutters>
                <span class="d-block secondary--text text-h5">1.203
                  <span class="body-2 secondary--text">incidencias ayer</span>
                </span>
                <v-col :class="['pa-0 text-right', $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200 ? 'mt-n3' : '']">
                  <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                  <span class="success--text body-1 font-weight-bold">50%</span>
                  <span :class="['body-2 ml-1 secondary--text', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs periodo anterior</span>
                </v-col>
              </v-row>
              <v-col class="px-0 pb-0">
                <v-sheet class="px-3 py-2 rounded-sm" color="#C91818">
                  <div class="d-flex justify-space-between">
                    <span class="body-1 white--text font-weight-light">Factor 1</span>
                    <span class="body-1 white--text font-weight-medium">22</span>
                  </div>
                </v-sheet>
                <v-sheet class="my-1 px-3 py-2 rounded-sm" color="#E44848">
                  <div class="d-flex justify-space-between">
                    <span class="body-1 white--text font-weight-light">Factor 2</span>
                    <span class="body-1 white--text font-weight-medium">22</span>
                  </div>
                </v-sheet>
                <v-sheet class="px-3 py-2 rounded-sm" color="#EF9696">
                  <div class="d-flex justify-space-between">
                    <span class="body-1 white--text font-weight-light">Factor 3</span>
                    <span class="body-1 white--text font-weight-medium">84</span>
                  </div>
                </v-sheet>
                <v-sheet class="mt-1 px-3 py-2 rounded-sm" color="success">
                  <div class="d-flex justify-space-between">
                    <span class="body-1 white--text font-weight-light">Factor 4</span>
                    <span class="body-1 white--text font-weight-medium">151</span>
                  </div>
                </v-sheet>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" class="pl-0 pb-0">
          <v-card class="pt-4 pb-5 rounded-md" flat>
            <v-card-title class="pa-0">
              <v-toolbar color="transparent" flat height="30">
                <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Frecuencia de incidencias</v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-row class="my-5" align="center">
              <v-col cols="4">
                <div class="d-flex align-center pl-5">
                  <v-avatar color="primary" size="10"/>
                  <span class="subtitle-1 mx-2 secondary--text font-weight-medium">6</span>
                  <span class="body-2 secondary--text">por hora hoy</span>
                </div>
                <div class="d-flex align-center mt-3 pl-5">
                  <v-avatar color="purpleMedium" size="10"/>
                  <span class="subtitle-2 mx-2 secondary--text font-weight-medium">8</span>
                  <span class="body-2 secondary--text">por hora hoy</span>
                </div>
                <v-col class="mt-5">
                  <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                  <span class="success--text body-1 font-weight-bold">50%</span>
                  <span :class="['body-2 ml-1 secondary--text', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs periodo anterior</span>
                </v-col>
              </v-col>
              <v-col cols="8" class="py-0 pr-8 mt-n3">
                <div style="width: 100%;">
                  <chart-line
                    axe-type-y="number"
                    :chart-data="receivedChartData"
                    chart-id="chart1"
                    :height="185"
                    :number-rounded="true"
                    :show-axe-x="true"
                    :show-draw-border-grid-lines-axe-y="true"
                    :show-grid-lines-axe-y="true"
                    :show-point="true"
                    :borderWidth="1"
                    :value-max-ticks-limit="5"
                    :zIndexTooltip="80"
                    style="position:relative;"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

       <v-col cols="12" class="flex-grow-0 flex-shrink-0 px-0">
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-card class="py-4 rounded-md" flat>
                  <v-card-title class="pa-0">
                    <v-toolbar color="transparent" flat height="30">
                      <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Cumplimiento operacional</v-toolbar-title>
                    </v-toolbar>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-row align="center" justify="center" class="mr-5">
                        <v-btn class="body-2" :to="{ name: 'ComplianceOperation' }" color="primary" text small :ripple="false">Ir a compliance <v-icon right>mdi-open-in-new</v-icon></v-btn>
                      </v-row>
                    </v-toolbar-items>
                  </v-card-title>
                  <v-col class="px-5 pt-3 pb-0">
                    <span class="d-block text-h4">96.7%
                      <span class="body-2">ayer</span>
                    </span>
                    <v-row no-gutters>
                      <span class="d-block text-h5">96.7%
                        <span class="body-2">antes de ayer</span>
                      </span>
                      <v-col :class="['pa-0 text-right', $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200 ? 'mt-n3' : '']">
                        <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                        <span class="success--text body-1 font-weight-bold">50%</span>
                        <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs antes de ayer</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="py-4 rounded-md" flat>
                  <v-card-title class="pa-0">
                    <v-toolbar color="transparent" flat height="30">
                      <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Tiempo promedio por transacción</v-toolbar-title>
                    </v-toolbar>
                  </v-card-title>
                  <v-col class="px-5 pt-3 pb-0">
                    <span class="d-block secondary--text text-h4">6:31
                      <span class="body-2 secondary--text">minutos hoy</span>
                    </span>
                    <v-row no-gutters>
                      <span class="d-block text-h5 secondary--text">7:14
                        <span class="body-2 secondary--text">minutos ayer</span>
                      </span>
                      <v-col :class="['pa-0 text-right', $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200 ? 'mt-n3' : '']">
                        <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                        <span class="success--text body-1 font-weight-bold">4.3%</span>
                        <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs ayer</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pl-0 pb-0">
            <v-card class="pt-4 pb-5 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Transacciones por hora</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-row class="my-5" align="center">
                <v-col cols="4">
                 <div class="d-flex align-center pl-5">
                    <v-avatar color="primary" size="10"/>
                    <span class="subtitle-1 mx-2 secondary--text font-weight-medium">6</span>
                    <span class="body-2 secondary--text">por hora hoy</span>
                 </div>
                  <div class="d-flex align-center mt-3 pl-5">
                    <v-avatar color="purpleMedium" size="10"/>
                    <span class="subtitle-2 mx-2 secondary--text font-weight-medium">8</span>
                    <span class="body-2 secondary--text">por hora hoy</span>
                 </div>
                  <v-col class="mt-5">
                    <v-icon class="rotate" size="25" color="red">mdi-chart-line-variant</v-icon>
                    <span class="red--text body-1 font-weight-bold">25%</span>
                    <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs ayer</span>
                  </v-col>
                </v-col>
                <v-col cols="8" class="py-0 pr-8 mt-n3">
                  <div style="width: 100%;">
                    <chart-line
                      axe-type-y="number"
                      :chart-data="receivedChartData"
                      chart-id="chart2"
                      :height="185"
                      :number-rounded="true"
                      :show-axe-x="true"
                      :show-draw-border-grid-lines-axe-y="true"
                      :show-grid-lines-axe-y="true"
                      :show-point="true"
                      :borderWidth="1"
                      :value-max-ticks-limit="5"
                      :zIndexTooltip="80"
                      style="position:relative;"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!--barras -->
      <v-col cols="12" class="flex-grow-0 flex-shrink-0 px-0">
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-card class="py-4 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Transacciones por unidad de negocio</v-toolbar-title>
                </v-toolbar>
                <v-spacer />
                <v-toolbar-items>
                  <v-row align="center" justify="center" class="mr-5">
                    <div class="d-flex align-center pl-5">
                      <v-avatar color="primary" size="10"/>
                      <span class="subtitle-2 ml-2 fontDraw--text font-weight-medium">Hoy</span>
                    </div>
                     <div class="d-flex align-center pl-5">
                      <v-avatar color="purpleMedium" size="10"/>
                      <span class="subtitle-2 ml-2 fontDraw--text font-weight-medium">Ayer</span>
                    </div>
                  </v-row>
                </v-toolbar-items>
              </v-card-title>
             <v-row class="my-2">
              <v-col cols="4" class="pl-9 pb-0 pr-5">
                <div class="d-flex align-center mt-3">
                  <v-avatar color="primary" size="28">
                    <v-icon dark size="16">mdi-cart</v-icon>
                  </v-avatar>
                  <span class="caption ml-4">Supermercados mayoristas</span>
                </div>
                 <div class="d-flex align-center mt-3">
                  <v-avatar color="primary" size="28">
                    <v-icon dark size="16">mdi-mortar-pestle-plus</v-icon>
                  </v-avatar>
                  <span class="caption ml-4">Farmacias</span>
                </div>
                 <div class="d-flex align-center my-3">
                  <v-avatar color="primary" size="28">
                    <v-icon dark size="16">mdi-hanger</v-icon>
                  </v-avatar>
                  <span class="caption ml-4">Tiendas por departamento</span>
                </div>
                 <div class="d-flex align-center">
                  <v-avatar color="primary" size="28">
                    <v-icon dark size="16">mdi-home</v-icon>
                  </v-avatar>
                  <span class="caption ml-4">Mejoramiento del hogar</span>
                </div>
              </v-col>
               <v-col cols="8" class="pl-0 py-0 mt-5" style="border-left: 2px solid var(--light-blue-grey)">
                  <v-sheet class="mb-1 rounded-r-xs" color="primary" width="80%" height="10px"></v-sheet>
                  <v-sheet class="mb-0 rounded-r-xs" color="purpleMedium" width="70%" height="10px"></v-sheet>

                  <v-sheet class="mb-1 mt-6 rounded-r-xs" color="primary" width="75%" height="10px"></v-sheet>
                  <v-sheet class="mb-0 rounded-r-xs" color="purpleMedium" width="60%" height="10px"></v-sheet>

                  <v-sheet class="mb-1 mt-6 rounded-r-xs" color="primary" width="65%" height="10px"></v-sheet>
                  <v-sheet class="mb-0 rounded-r-xs" color="purpleMedium" width="77%" height="10px"></v-sheet>

                  <v-sheet class="mb-1 mt-6 rounded-r-xs" color="primary" width="65%" height="10px"></v-sheet>
                  <v-sheet class="mb-0 rounded-r-xs" color="purpleMedium" width="77%" height="10px"></v-sheet>
              </v-col>
             </v-row>
            </v-card>
          </v-col>
          <v-col cols="6" class="pb-0">
            <v-card class="py-4 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Totales por unidad de negocio</v-toolbar-title>
                </v-toolbar>
                <v-spacer />
                <v-toolbar-items>
                  <v-row align="center" justify="center" class="mr-5">
                    <div class="d-flex align-center pl-5">
                      <v-avatar color="primary" size="10"/>
                      <span class="subtitle-2 ml-2 fontDraw--text font-weight-medium">Hoy</span>
                    </div>
                     <div class="d-flex align-center pl-5">
                      <v-avatar color="purpleMedium" size="10"/>
                      <span class="subtitle-2 ml-2 fontDraw--text font-weight-medium">Ayer</span>
                    </div>
                  </v-row>
                </v-toolbar-items>
              </v-card-title>
             <v-row class="my-2">
              <v-col cols="4" class="pl-9 pb-0 pr-5">
                <div class="d-flex align-center mt-3">
                  <v-avatar color="primary" size="28">
                    <v-icon dark size="16">mdi-cart</v-icon>
                  </v-avatar>
                  <span class="caption ml-4">Supermercados mayoristas</span>
                </div>
                 <div class="d-flex align-center mt-3">
                  <v-avatar color="primary" size="28">
                    <v-icon dark size="16">mdi-mortar-pestle-plus</v-icon>
                  </v-avatar>
                  <span class="caption ml-4">Farmacias</span>
                </div>
                 <div class="d-flex align-center my-3">
                  <v-avatar color="primary" size="28">
                    <v-icon dark size="16">mdi-hanger</v-icon>
                  </v-avatar>
                  <span class="caption ml-4">Tiendas por departamento</span>
                </div>
                 <div class="d-flex align-center">
                  <v-avatar color="primary" size="28">
                    <v-icon dark size="16">mdi-home</v-icon>
                  </v-avatar>
                  <span class="caption ml-4">Mejoramiento del hogar</span>
                </div>
              </v-col>
               <v-col cols="8" class="pl-0 py-0 mt-5" style="border-left: 2px solid var(--light-blue-grey)">
                  <v-sheet class="mb-1 rounded-r-xs" color="primary" width="80%" height="10px"></v-sheet>
                  <v-sheet class="mb-0 rounded-r-xs" color="purpleMedium" width="70%" height="10px"></v-sheet>

                  <v-sheet class="mb-1 mt-6 rounded-r-xs" color="primary" width="75%" height="10px"></v-sheet>
                  <v-sheet class="mb-0 rounded-r-xs" color="purpleMedium" width="60%" height="10px"></v-sheet>

                  <v-sheet class="mb-1 mt-6 rounded-r-xs" color="primary" width="65%" height="10px"></v-sheet>
                  <v-sheet class="mb-0 rounded-r-xs" color="purpleMedium" width="77%" height="10px"></v-sheet>

                  <v-sheet class="mb-1 mt-6 rounded-r-xs" color="primary" width="65%" height="10px"></v-sheet>
                  <v-sheet class="mb-0 rounded-r-xs" color="purpleMedium" width="77%" height="10px"></v-sheet>
              </v-col>
             </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!--- -->
      <v-col cols="12" class="flex-grow-0 flex-shrink-0 px-0">
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-card class="py-4 rounded-md" flat>
                  <v-card-title class="pa-0">
                    <v-toolbar color="transparent" flat height="30">
                      <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Ingresos totales</v-toolbar-title>
                    </v-toolbar>
                  </v-card-title>
                  <v-col class="px-5 pt-3 pb-0">
                    <span class="d-block text-h4">$ 406.9 <span class="subtitle-2">MM </span>
                      <span class="body-2">hoy</span>
                    </span>
                    <v-row no-gutters>
                      <span class="d-block text-h5">$ 410.5  <span class="body-1">MM </span>
                        <span class="body-2">ayer</span>
                      </span>
                      <v-col :class="['pa-0 text-right', $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200 ? 'mt-n3' : '']">
                       <v-icon class="rotate" size="25" color="red">mdi-chart-line-variant</v-icon>
                        <span class="red--text body-1 font-weight-bold">4.3%</span>
                        <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs ayer</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="py-4 rounded-md" flat>
                  <v-card-title class="pa-0">
                    <v-toolbar color="transparent" flat height="30">
                      <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Tiempo promedio por transacción</v-toolbar-title>
                    </v-toolbar>
                  </v-card-title>
                  <v-col class="px-5 pt-3 pb-0">
                    <span class="d-block secondary--text text-h4">6:31
                      <span class="body-2 secondary--text">minutos hoy</span>
                    </span>
                    <v-row no-gutters>
                      <span class="d-block text-h5 secondary--text">7:14
                        <span class="body-2 secondary--text">minutos ayer</span>
                      </span>
                      <v-col :class="['pa-0 text-right', $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200 ? 'mt-n3' : '']">
                        <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                        <span class="success--text body-1 font-weight-bold">4.3%</span>
                        <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs ayer</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="px-0">
            <v-card class="pt-4 pb-5 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Ingresos por hora</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-row class="my-5" align="center">
                <v-col cols="4">
                 <div class="d-flex align-center pl-5">
                    <v-avatar color="primary" size="10"/>
                    <span class="subtitle-1 mx-2 secondary--text font-weight-medium">$563.440</span>
                    <span class="body-2 secondary--text">por hora hoy</span>
                 </div>
                  <div class="d-flex align-center mt-3 pl-5">
                    <v-avatar color="purpleMedium" size="10"/>
                    <span class="subtitle-2 mx-2 secondary--text font-weight-medium">$636.910</span>
                    <span class="body-2 secondary--text">por hora hoy</span>
                 </div>
                  <v-col class="mt-5">
                    <v-icon class="rotate" size="25" color="red">mdi-chart-line-variant</v-icon>
                    <span class="red--text body-1 font-weight-bold">10.6%</span>
                    <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs ayer</span>
                  </v-col>
                </v-col>
                <v-col cols="8" class="py-0 pr-8 mt-n3">
                  <div style="width: 100%;">
                    <chart-line
                      axe-type-y="number"
                      :chart-data="receivedChartData"
                      chart-id="chart3"
                      :height="185"
                      :number-rounded="true"
                      :show-axe-x="true"
                      :show-draw-border-grid-lines-axe-y="true"
                      :show-grid-lines-axe-y="true"
                      :show-point="true"
                      :borderWidth="1"
                      :value-max-ticks-limit="5"
                      :zIndexTooltip="80"
                      style="position:relative;"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="py-0">
            <v-card class="py-4 rounded-md" flat :height="280">
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Tasa de utilización tiendas</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-row class="mt-5 py-2" no-gutters align="center">
                <v-col :cols="6" class="px-0 py-4 text-center">
                  <div class="d-flex justify-center" style="width: 100%;">
                    <section class="container-donut">
                      <chart-doughnut :chart-data="chartDonut" :colors="['#0352CC', '#8BAFE8', '#023A91', '#B1C9EF']" :tooltips="true" id="donut-1" :height="$vuetify.breakpoint.width <= 1080 ? 75 : 150" />
                      <!-- <p class="label-donut secondary--text my-10">
                        <span :class="`${$vuetify.breakpoint.width <= 1200 ? 'body-1' : 'text-h4'} font-weight-bold`">00,0</span>
                        <span :class="`${$vuetify.breakpoint.width <= 1200 ? 'body-2' : 'subtitle-1'}`">mill</span>
                      </p> -->
                    </section>
                  </div>
                </v-col>
                <v-col cols="6" class="pb-1 pr-5">
                  <v-simple-table class="table-detail table-dense no-hover" dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="body-2 pl-0 pr-1 pb-1" style="width: 15px;"><v-avatar class="mt-n2" size="10" color="primary" /></td>
                          <td class="body-2 pa-0 pb-2 secondary--text font-weight-semibold">Activas</td>
                          <td class="text-right body-2 pa-0 pb-2 secondary--text font-weight-semibold">239</td>
                        </tr>
                        <tr>
                          <td class="body-2 pl-0 pr-1 pb-1"><v-avatar class="mt-n2" size="10" color="purpleMedium" /></td>
                          <td class="body-2 pa-0 pb-2 secondary--text">Inactivas</td>
                          <td class="text-right body-2 pb-2 pa-0 secondary--text">116</td>
                        </tr>
                        <tr>
                          <td class="body-2 pl-0 pr-1 pb-1"><v-avatar class="mt-n2" size="10" color="blueDarken" /></td>
                          <td class="body-2 pa-0 pb-2 secondary--text">En pausa</td>
                          <td class="text-right body-2 pa-0 pb-2 secondary--text">30</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6" class="pl-0 py-0 pr-0">
            <v-card class="pt-4 pb-5 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Transacciones por tienda</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-row class="my-5" align="center">
                <v-col cols="4">
                 <div class="d-flex align-center pl-5">
                    <v-avatar color="primary" size="10"/>
                    <span class="subtitle-1 ml-2 secondary--text font-weight-medium">90%</span>
                    <span class="body-2 secondary--text pt-1 ml-1">56 tiendas hoy</span>
                 </div>
                  <div class="d-flex align-center mt-3 pl-5">
                    <v-avatar color="purpleMedium" size="10"/>
                    <span class="subtitle-2 ml-2 secondary--text font-weight-medium">86%</span>
                    <span class="body-2 secondary--text pt-1 ml-1"> 52 tiendas ayer</span>
                 </div>
                  <v-col class="mt-5">
                     <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                    <span class="success--text body-1 font-weight-bold">4%</span>
                    <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs ayer</span>
                  </v-col>
                </v-col>
                <v-col cols="8" class="py-0 pr-8 mt-n3">
                  <div style="width: 100%;">
                    <chart-line
                      axe-type-y="number"
                      :chart-data="receivedChartData"
                      chart-id="issueChartData"
                      :height="185"
                      :number-rounded="true"
                      :show-axe-x="true"
                      :show-draw-border-grid-lines-axe-y="true"
                      :show-grid-lines-axe-y="true"
                      :show-point="true"
                      :borderWidth="1"
                      :value-max-ticks-limit="5"
                      :zIndexTooltip="80"
                      style="position:relative;"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col cols="6" class="py-0">
            <v-card class="py-4 rounded-md" flat :height="280">
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Tasa de utilización PoS</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-row class="mt-5 py-2" no-gutters align="center">
                <v-col :cols="6" class="px-0 py-4 text-center">
                  <div class="d-flex justify-center" style="width: 100%;">
                    <section class="container-donut">
                      <chart-doughnut :chart-data="chartDonut1" :colors="['#0352CC', '#8BAFE8', '#023A91', '#DD1A1A']" :tooltips="true" id="donut-2" :height="$vuetify.breakpoint.width <= 1080 ? 75 : 150" />
                      <!-- <p class="label-donut secondary--text my-10">
                        <span :class="`${$vuetify.breakpoint.width <= 1200 ? 'body-1' : 'text-h4'} font-weight-bold`">00,0</span>
                        <span :class="`${$vuetify.breakpoint.width <= 1200 ? 'body-2' : 'subtitle-1'}`">mill</span>
                      </p> -->
                    </section>
                  </div>
                </v-col>
                <v-col cols="6" class="pb-1 pr-5">
                  <v-simple-table class="table-detail table-dense no-hover" dense>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="body-2 pl-0 pr-1 pb-1" style="width: 15px;"><v-avatar class="mt-n2" size="10" color="primary" /></td>
                          <td class="body-2 pa-0 pb-2 secondary--text font-weight-semibold" style="width: 130px; white-space: wrap !important;">Activas</td>
                          <td class="text-right body-2 pa-0 pb-2 secondary--text font-weight-semibold">151</td>
                        </tr>
                        <tr>
                          <td class="body-2 pl-0 pr-1 pb-1"><v-avatar class="mt-n2" size="10" color="purpleMedium" /></td>
                          <td class="body-2 pa-0 pb-2 secondary--text" style="width: 130px; white-space: wrap !important;">Inactivas</td>
                          <td class="text-right body-2 pb-2 pa-0 secondary--text">84</td>
                        </tr>
                        <tr>
                          <td class="body-2 pl-0 pr-1 pb-1"><v-avatar class="mt-n2" size="10" color="blueDarken" /></td>
                          <td class="body-2 pa-0 pb-2 secondary--text" style="width: 130px; white-space: wrap !important;">En pausa</td>
                          <td class="text-right body-2 pa-0 pb-2 secondary--text">22</td>
                        </tr>
                        <tr>
                          <td class="body-2 pl-0 pr-1 pb-1"><v-avatar class="mt-n2" size="10" color="error" /></td>
                          <td class="body-2 pa-0 pb-2 secondary--text" style="width: 130px; white-space: wrap !important;">Incidente</td>
                          <td class="text-right body-2 pa-0 pb-2 secondary--text">22</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="6" class="pl-0 py-0 px-0">
            <v-card class="pt-4 pb-5 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Utilización de PoS</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-row class="my-5" align="center">
                <v-col cols="4">
                 <div class="d-flex align-center pl-5">
                    <v-avatar color="primary" size="10"/>
                    <span class="subtitle-1 ml-2 secondary--text font-weight-medium">90%</span>
                    <span class="body-2 secondary--text pt-1 ml-1"> 3.430 PoS hoy</span>
                 </div>
                  <div class="d-flex align-center mt-3 pl-5">
                    <v-avatar color="purpleMedium" size="10"/>
                    <span class="subtitle-2 ml-2 secondary--text font-weight-medium">86%</span>
                    <span class="body-2 secondary--text pt-1 ml-1"> 3.120 PoS ayer</span>
                 </div>
                  <v-col class="mt-5">
                     <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                    <span class="success--text body-1 font-weight-bold">9%</span>
                    <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs ayer</span>
                  </v-col>
                </v-col>
                <v-col cols="8" class="py-0 pr-8 mt-n3">
                  <div style="width: 100%;">
                    <chart-line
                      axe-type-y="number"
                      :chart-data="receivedChartData"
                      chart-id="chart4"
                      :height="185"
                      :number-rounded="true"
                      :show-axe-x="true"
                      :show-draw-border-grid-lines-axe-y="true"
                      :show-grid-lines-axe-y="true"
                      :show-point="true"
                      :borderWidth="1"
                      :value-max-ticks-limit="5"
                      :zIndexTooltip="80"
                      style="position:relative;"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import productList from '@/data/productList' // product
import ChartLine from '@/components/charts/ChartLine'
import ChartDoughnut from '@/components/charts/ChartDoughnut'

export default {
   components: {
    ChartLine,
    ChartDoughnut
  },
  data: () => ({
    products: productList,
      receivedChartData: {
      labels: [
        "00am",
        "02am",
        "04am",
        "06am",
        "08am",
        "10am",
        "12am",
        "14am",
        "16pm",
        "18pm",
        "20pm",
        "22pm",
        "00am"
      ],
      datasets: [
        {
          label: "00am",
          percent_change: '20.84',
          backgroundColor: 'rgba(177, 201, 239, 1)',
          borderColor: '#0352CC',
          fill: true,
          data: [40, 35, 40, 45, 35, 45, 40, 50, 45, 55, 50, 55, 60]
        },
        {
          label: "02am",
          percent_change: '-90.34',
          backgroundColor: 'rgba(230, 238, 250, 1)',
          borderColor: '#B1C9EF',
          fill: true,
          data: [50, 40, 45, 40, 50, 35, 40, 45, 40, 45, 40, 25, 25]
        }
      ]
    },
    chartDonut: {
      labels: ['Activas', 'Inactivas', 'En pausa'],
      datasets: [
        {
          data: [239, 116, 30]
        }
      ]
    },
    chartDonut1: {
       labels: ['Activas', 'Inactivas', 'En pausa', 'Incidente'],
      datasets: [
        {
          data: [151, 84, 22, 22]
        }
      ]
    }
  }),
  methods: {
    setProduct (product = '') {
      localStorage.setItem('current-product', product.id)
      this.$store.commit('base/SET_PRODUCT', product.id ?? 'insight')

      this.$router.push({ name: product.route, params: this.$route.params })
    }
  }
}
</script>